<template>
    <div class="orgcontainer">
        <LastTrades :allCoins="coins" />
        <Market :allCoins="coins" />
    </div>
</template>
<script>
import LastTrades from '../HomePage/LastTrades.vue';
import Market from '../HomePage/Market.vue';
export default {
    name:'MatketPublic',
    components:{
        LastTrades,
        Market
    },
    data(){
        return{
            coins:[],
        }
    },
    methods:{
        async setData() {
                    this.state.loading = true
                    const res = await this.$axios.get('/trades/market-info',{
                        params: {
                            weeklyDataIncluded: true,
                        }
                    })
                    this.coins= res.content
            },
    },
    mounted() {
        this.setData();
    },
}
</script>

<style lang="scss" scoped>
.orgcontainer{
    padding-top:100px !important ;
}
</style>